import React, { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import MailchimpForm from "../mailchimp-form";
import RedirectButton from "../redirect-button";

const Hero = () => {
  const taglines = [
    " Neuropathy.",
    " Joint Pain.",
    " Foot Pain.",
    " ED.",
    " Carpal Tunnel."
  ];
  const newTaglines = [
    {
      pre: "No more ",
      post: "neuropathy."
    },
    {
      pre: "No more ",
      post: "joint pain."
    },
    {
      pre: "No more ",
      post: "ED."
    },
    {
      pre: "",
      post: "Get better faster."
    },
    {
      pre: "No more ",
      post: "foot pain."
    },
    {
      pre: "No more ",
      post: "carpal tunnel."
    }
  ];
  const [activeTagline, setActiveTagline] = useState(0);
  const [fading, setFading] = useState(false);
  const sizeRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof activeTagline !== undefined) {
        setFading(true);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (fading === true) {
      setTimeout(() => {
        setActiveTagline((activeTagline + 1) % taglines.length);
        setFading(false);
      }, 500);
    }
  }, [fading]);

  return (
    <>
      <div className="w-full h-full relative hidden lg:block">
        <div style={{ height: "100%" }}>
          <div className="pl-12 xl:pl-16 pr-4 z-40 relative flex flex-col justify-between h-full">
            <div>
              <h2
                className="text-4xl font-bold mt-8 mb-6 leading-normal"
                style={{ color: "#231F20" }}
              >
                No pills, no shots, no surgery.
              </h2>
              <p
                className={`text-4xl font-bold leading-normal inline ${
                  fading && (activeTagline == 3 || activeTagline == 2)
                    ? "opacity-0"
                    : "opacity-100"
                }`}
                style={{
                  color: "#231F20",
                  transition: "opacity 1s ease-in-out"
                }}
              >
                {newTaglines[activeTagline].pre}
                <span
                  className={`${fading ? "opacity-0" : "opacity-100"}`}
                  style={{ transition: "opacity 1s ease-in-out" }}
                >
                  {newTaglines[activeTagline].post}
                </span>
              </p>
            </div>
            <div className="mb-12 self-start">
              <RedirectButton classes="max-w-sm self-center" light>
                Schedule appointment
              </RedirectButton>
            </div>
          </div>

          <div className="mb-12 self-start"></div>
          {/*<div
            className="w-full absolute bottom-0 bg-gray-500 left-0 z-50 bg-opacity-75"
            style={{
              height: "31%",
              backgorundColor: "rgba(0.192,0.51,0.808,0.3)",
            }}
          >
            {" "}
            <MailchimpForm></MailchimpForm>
          </div>*/}
        </div>

        <StaticImage
          src="../../images/spring23-desktop-v3.png"
          alt="Man and woman walking through spring meadow"
          className="w-full z-30 top-0"
          placeholder="blurred"
          objectPosition={"top center"}
          fluid
          style={{
            height: "100%",
            position: "absolute",
            bottom: 0,
            backgroundSize: "cover",
            backgroundPosition: "top"
          }}
        ></StaticImage>
      </div>

      <div className=" h-full relative lg:hidden">
        <div className="pl-8 pr-4 z-40 relative flex flex-col justify-between h-full text-left">
          {/*<div>
            <h2
              className="text-3xl sm:px-8 sm:text-4xl font-bold mb-6 sm:text-left leading-normal"
              style={{ color: "#231F20" }}
            >
              Get better faster.
            </h2>
          </div>*/}
          <div className="sm:ml-4">
            <h2
              className="sm:text-4xl text-2xl font-bold sm:mt-12 mt-8 mb-8 leading-normal"
              style={{ color: "#231F20" }}
            >
              No pills, no shots, no surgery.
            </h2>
            <p
              className={`sm:text-4xl text-2xl font-bold leading-normal inline ${
                fading && (activeTagline == 3 || activeTagline == 2)
                  ? "opacity-0"
                  : "opacity-100"
              }`}
              style={{ color: "#231F20", transition: "opacity 1s ease-in-out" }}
            >
              {newTaglines[activeTagline].pre}
              <span
                className={`${fading ? "opacity-0" : "opacity-100"}`}
                style={{ transition: "opacity 1s ease-in-out" }}
              >
                {newTaglines[activeTagline].post}
              </span>
            </p>
          </div>
          <div className="mb-10 self-center">
            <RedirectButton classes="max-w-sm self-center" light>
              Schedule appointment
            </RedirectButton>
          </div>
        </div>
        <StaticImage
          src="../../images/spring23-mobile-v3.png"
          alt="Man and woman riding bicycles across bridge in sunny green forest"
          className="w-full z-30"
          placeholder="blurred"
          fluid
          style={{
            height: "100%",
            position: "absolute",
            bottom: 0,
            backgroundPosition: "65% 100%"
          }}
        ></StaticImage>
      </div>
    </>
  );
};

export default Hero;
